import "./Services.css"
import { Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import InfoTile from "../../components/InfoTile/InfoTile"
import InfoTileReversed from "../../components/InfoTile/InfoTileReversed"

function Services() {
    return (
        <div className="w-100">
            <Row className="text-center bg-dark text-light p-3">
                <h1>Our Services</h1>
            </Row>
            <div className="p-3">
                <InfoTile
                    title="Web Development."
                    description="We are, and have been, at an age where a strong online presence
                    makes a world of difference to most business, big or small. We
                    offer only the best practices as recommended by our senior
                    engineers."
                />
                <InfoTileReversed
                    title="Mobile Development."
                    description="The past few years have seen a myriad of mobile development technologies
                    pop up, from native technologies to cross-platform ones. Luckily, you won't have to worry
                    about that as our team is made up of passionate developers with diverse backgrounds. We have
                    utmost confidence in our ability to tackle not just the on-demand technologies, but the lesser/newer
                    ones as well."
                />
                <InfoTile
                    title="CRM Integration."
                    description="Do you have a partnership with a CRM like HubSpot or Zoho and need help with the more
                    technical tasks? We've got you covered. Where it's a project from the ground up or partial integration
                    into an existing project, we will use the relevant tools to ensure uninterrupted integrations"
                />
                <InfoTileReversed
                    title="Technical Consultation."
                    description="You come up with the idea and we'll work with you to turn that idea into a realistic and well thought out
                    blueprint. Should you decide that you would like us to also implement this idea, we will take your project all the way from the
                    infant stages and turn it into a fully mature and future-proof project."
                />
            </div>
        </div>
    )
}

export default withRouter(Services)
