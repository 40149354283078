import { Row } from "react-bootstrap"

function InfoTile({ title = "", description = "", extra = "" }) {
    return (
        <div className="d-flex justify-content-center">
            <Row className="mt-5 mx-5 w-75 row align-self-center">
                <div className="col-sm px-5">
                    <div>
                        <h4>{title}</h4>
                    </div>
                    <br />
                    <div className="mt-1">
                        <p>
                            {description}

                            {extra ? [<br />, <br />, extra] : []}
                        </p>
                    </div>
                </div>
                <div className="bg-dark" style={{ width: "5px" }} />
            </Row>
        </div>
    )
}

export default InfoTile
