import TermsOfUseText from "../../constants/TermsOfUseText"

function TermsOfUse() {
    return (
        <div className="p-5">
            <TermsOfUseText />
        </div>
    )
}

export default TermsOfUse
