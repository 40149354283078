import React, {useState} from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteProps,
} from "react-router-dom"
import Home from "./pages/Home/Home"
import "./App.css"
import Base from "./partials/Base/Base"
import Services from "./pages/Services/Services"
import ContactUs from "./pages/ContactUs/ContactUs"
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse"
import About from "./pages/About/About"
import { Helmet } from "react-helmet"

function App() {
    const [title, setTitle] = useState("")
    const [metaDescription, setMetaDescription] = useState("")

    const PublicRoute: React.FC<RouteProps> = ({
        component,
        path,
        exact = false,
    }) => {

        switch (path) {
            case "/":
                setTitle("Home")
                setMetaDescription(
                    "We are a progressive software development and consulting company.",
                )
                break
            case "/home":
                setTitle("Home")
                setMetaDescription(
                    "We are a progressive software development and consulting company.",
                )
                break
            case "/services":
                setTitle("Services")
                setMetaDescription(
                    "We offer a range of services that can take your software idea from conception to actualization.",
                )
                break
            case "/contact-us":
                setTitle("Contact Us")
                setMetaDescription(
                    "Do contact us if you would like to initiate a potential collaboration.",
                )
                break
            case "/about":
                setTitle("About")
                setMetaDescription(
                    "We are a team of very enthusiastic software engineers with significant cumulative experience. Our qualifications ensure that we deliver on projects in a timely and professional manner.",
                )
                break
            default:
                setTitle("")
                setMetaDescription("")
        }

        return <Route exact={exact} path={path} component={component} />
    }

    return (
        <Router>
            <Base>
                <Helmet>
                    <title>{`Devorithm | ${title}`}</title>
                    <meta name="description" content={metaDescription} />
                </Helmet>
                <Switch>
                    <PublicRoute exact path="/" component={Home} />
                    <PublicRoute exact path="/home" component={Home} />
                    <PublicRoute path="/services" component={Services} />
                    <PublicRoute path="/contact-us" component={ContactUs} />
                    <PublicRoute
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                    />
                    <PublicRoute path="/terms-of-use" component={TermsOfUse} />
                    <PublicRoute path="/about" component={About} />
                </Switch>
            </Base>
        </Router>
    )
}

export default App
