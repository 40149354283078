import { Row } from "react-bootstrap"
import hexagonOutlined from "../../assets/icons/hexagon-outlined.svg"
import webDevIcon from "../../assets/icons/web-dev.svg"

function InfoTile({ title = "", description = "" }) {
    const MOBILE_WIDTH = 600

    return (
        <div className="d-flex justify-content-center">
            <Row className="mt-5 w-75 row align-self-center">
                {window.innerWidth > MOBILE_WIDTH ? (
                    <div
                        className="container d-flex justify-content-center"
                        style={{
                            backgroundImage: `url(${hexagonOutlined})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100px",
                            height: "100px",
                        }}
                    >
                        <span
                            className="row justify-content-center align-self-center text-center"
                            style={{ width: "50px", height: "50px" }}
                        >
                            <img
                                src={webDevIcon}
                                alt="webdev icon"
                                style={{ backgroundSize: "contain" }}
                            />
                        </span>
                    </div>
                ) : (
                    []
                )}
                <div className="col-sm px-5">
                    <div
                        className={
                            window.innerWidth > MOBILE_WIDTH ? "typewriter" : ""
                        }
                    >
                        <h4>{title}</h4>
                    </div>
                    <div className="mt-1">
                        <p>{description}</p>
                    </div>
                </div>
                <div className="bg-dark" style={{ width: "5px" }} />
            </Row>
        </div>
    )
}

export default InfoTile
