import { Row } from "react-bootstrap"
import { FormEvent, useState } from "react"
import axios from "axios"

function ContactUs() {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [sendEmailError, setSendEmailError] = useState("")
    const [sendEmailSuccess, setSendEmailSuccess] = useState("")

    async function sendEmail(event: FormEvent) {
        event.preventDefault()
        setSendEmailError("")

        try {
            await axios.post("/api/contact", {
                firstName,
                lastName,
                companyName,
                email,
                message,
            })

            setSendEmailSuccess(
                "Your message has been sent. We will do our best to respond swiftly.",
            )
        } catch (e) {
            setSendEmailError(
                "We are unfortunately unable to send your message. Please retry now or at a later time.",
            )
        }
    }

    return (
        <div className="w-100">
            <Row className="text-center bg-dark text-light p-3">
                <h1>Contact Us</h1>
            </Row>
            <div className="my-5 d-flex justify-content-center">
                <div className="card w-75 p-3" style={{ maxWidth: "900px" }}>
                    {sendEmailError ? (
                        <div className="alert alert-danger" role="alert">
                            {sendEmailError}
                        </div>
                    ) : (
                        []
                    )}
                    {sendEmailSuccess ? (
                        <div className="alert alert-success" role="alert">
                            {sendEmailSuccess}
                        </div>
                    ) : (
                        []
                    )}
                    <div className="card-body">
                        <div className="text-center">
                            <h3 className="card-title">
                                We would love to work with you. Do contact us.
                            </h3>
                        </div>
                        <form onSubmit={(e) => sendEmail(e)}>
                            <div className="form-group mt-4">
                                <label htmlFor="first-name">
                                    <span style={{ color: "red" }}>*</span>First
                                    Name:
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="first-name"
                                    aria-describedby="firstName"
                                    placeholder="Your first name"
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="last-name">
                                    <span style={{ color: "red" }}>*</span>Last
                                    Name:
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="last-name"
                                    aria-describedby="lastName"
                                    placeholder="Your last name"
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="company-name">
                                    <span style={{ color: "red" }}>*</span>
                                    Company Name:
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="company-name"
                                    aria-describedby="companyName"
                                    placeholder="Your company's name"
                                    onChange={(e) =>
                                        setCompanyName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="email">
                                    <span style={{ color: "red" }}>*</span>
                                    Email:
                                </label>
                                <input
                                    required
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    aria-describedby="email"
                                    placeholder="Your contact email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="message">
                                    <span style={{ color: "red" }}>*</span>
                                    Message:
                                </label>
                                <textarea
                                    required
                                    className="form-control"
                                    id="message"
                                    rows={3}
                                    placeholder="How can we help?"
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-2"
                                    style={{ backgroundColor: "black" }}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
