import { Row, Container } from "react-bootstrap"
import DeveloperActivity from "../../assets/illustrations/undraw_Developer_activity_re_39tg.svg"
import Collection from "../../assets/illustrations/undraw_Collection_re_4h7d.svg"
import Upload from "../../assets/illustrations/undraw_going_up_ttm5.svg"
import InfoTileNoEffects from "../../components/InfoTile/InfoTileNoEffects"
import Digital22 from "../../assets/partner-logos/digital22.png"

function About() {
    return (
        <div className="w-100">
            <Row className="text-center bg-dark text-light p-3">
                <h1>About Us</h1>
            </Row>
            <Row className="p-3">
                <div className="col-2 overflow-hidden">
                    <img
                        className="img-fluid"
                        src={DeveloperActivity}
                        alt="developer activity"
                    />
                </div>
                <div className="col overflow-hidden">
                    <InfoTileNoEffects
                        title="Let us be the software solutions partner you never knew you needed."
                        description="Our team consists of very passionate and enthusiastic engineers
                    with a robust history of software experimentation and perfection. From static
                    projects to cloud-based ones, you can rest assured that you ideas will be
                    implemented with utmost quality and professionalism."
                        extra="Transparency is at our forefront at all times and you can expect to know
                    exactly what stage your project is at, at any point in time."
                    />
                    <div className="col-2">
                        <img className="img-fluid" src={Upload} alt="upload" />
                    </div>
                </div>
                <div className="col-2 overflow-hidden">
                    <img
                        className="img-fluid"
                        src={Collection}
                        alt="developer collection"
                    />
                </div>
            </Row>
            {/*<Row className="text-center text-secondary p-3">
                <h1>Our Current and Past Partners</h1>
            </Row>
            <Container className="text-center">
    </Container>*/}
        </div>
    )
}

export default About
