import "./Base.css"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

function Base(props: { children: any }) {
    const { children } = props

    return (
        <div className="h-100">
            <Header />
            <div style={{ minHeight: `${window.innerHeight}px` }}>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Base
