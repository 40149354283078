import { Nav, Navbar } from "react-bootstrap"
import "./Header.css"
import DevorithmLogo from "../../assets/Devorithm-logos/Devorithm-logos_transparent-cropped.png"

function Header() {
    return (
        <header className="p-3">
            <Navbar expand="lg">
                <Navbar.Brand href="/">
                    <img
                        className="pt-2"
                        style={{ height: "40px" }}
                        src={DevorithmLogo}
                        alt="devorithm logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end"
                >
                    <Nav className="me-0">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/services">Services</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default Header
