import { Col, Row } from "react-bootstrap"
import { PhoneOutlined, EmailOutlined } from "@material-ui/icons"

function Footer() {
    return (
        <Col className="p-5 bg-dark text-light">
            <Row>
                <Col>
                    <h6>Site Links</h6>
                    <div>
                        <a
                            href="/"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            Home
                        </a>
                    </div>
                    <div>
                        <a
                            href="/services"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            Services
                        </a>
                    </div>
                    <div>
                        <a
                            href="/about"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            About
                        </a>
                    </div>
                    <div>
                        <a
                            href="/contact-us"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            Contact Us
                        </a>
                    </div>
                </Col>
                <Col>
                    <h6>Contact</h6>
                    <div>
                        <a
                            href="tel:+1-902-314-5561"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            <PhoneOutlined /> +1-902-314-5561
                        </a>
                    </div>
                    <div>
                        <a
                            href="mailto:info@devorithm.com"
                            className="link-secondary d-inline text-decoration-none"
                        >
                            <EmailOutlined /> info@devorithm.com
                        </a>
                    </div>
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <Row>
                <div>
                    <hr className="text-light" />
                </div>
                <div className="container-fluid">
                    <p>&#169; {`${new Date().getFullYear()}`}</p>
                </div>
                <div className="container-fluid">
                    <a
                        href="/privacy-policy"
                        className="link-secondary d-inline text-decoration-none"
                    >
                        Privacy Policy
                    </a>
                    <p className="d-inline p-lg-2 px-2">|</p>
                    <a
                        href="/terms-of-use"
                        className="link-secondary text-decoration-none"
                    >
                        Terms of Use
                    </a>
                </div>
            </Row>
        </Col>
    )
}

export default Footer
