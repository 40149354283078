import homeBackground from "../../assets/backgrounds/home-background.jpg"

function Home() {
    return (
        <div
            className="d-flex justify-content-center overflow-hidden"
            style={{
                backgroundImage: `url(${homeBackground})`,
                height: `${window.innerHeight}px`,
                width: `${window.innerWidth}px`,
            }}
        >
            <div
                className="d-inline-block position-absolute top-50 p-5 m-auto w-75 text-center"
                style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
            >
                <h1 className="text-light">No hassle full-stack solutions</h1>
                <a href="/contact-us">
                    <button
                        type="button"
                        className="btn btn-primary mt-5 text-white"
                    >
                        Get Started
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Home
